import './bootstrap';
import '../css/app.css';
import '@scss/styles.scss';

import { hydrateRoot } from 'react-dom/client';

import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { LaravelReactI18nProvider} from 'laravel-react-i18n';
import { router } from '@inertiajs/react'
import AppLayout from "@/Layouts/AppLayout.jsx";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = hydrateRoot(el);
        root.render(
            <LaravelReactI18nProvider
                locale={props.initialPage.props.locale}
                fallbackLocale={'en'}
                files={import.meta.glob('/lang/*.json', {eager: true})}
            >
                <AppLayout>
                    <App {...props} />
                </AppLayout>
            </LaravelReactI18nProvider>
        );
    },
    progress: {
        color: '#4B5563',
    },
});
