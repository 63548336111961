import React from 'react';

export default function DecorContainer ({children, zIndex}) {



    return (
        <div className={`decor-container`} style={{zIndex: zIndex}}>
            {children}
        </div>
    );
}
