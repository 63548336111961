import React, {useContext, useEffect, useRef} from 'react';
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {getRandomInt, isIPadDesktopMode} from "@/Utils/index.js";
import {MarkupContext} from "@/Contexts/index.js";

gsap.registerPlugin(ScrollTrigger);

export default function DecorBlob ({typeNum=1, rotate, width, blur, x, y, zIndex, yBottom, opacity, animations, animDuration, gsapMove}) {

    const {stateMobileMode} = useContext(MarkupContext);
    const {isMobileMode} = stateMobileMode;

    const ref = useRef(null);

    animations = animations ?? [];
    animDuration = animDuration ?? 6;

    const classAnimations = [];

    if ( animations.includes('pulse') ) classAnimations.push('decor-blob--anim-pulse');

    const scrubVal = getRandomInt(50, 150) / 100;
    const rotateVal = 0;

    useEffect(() => {
        let tween, scrollTriggerInstance;

        if ( !isMobileMode && gsapMove && !isIPadDesktopMode() ) {
            gsap.set(ref.current, {y: 100});
            tween = gsap.to(ref.current, { y: 0, rotation: `+=${rotateVal}` });
            scrollTriggerInstance = ScrollTrigger.create({
                trigger: ref.current,
                start: "top bottom",
                end: "bottom top",
                scrub: scrubVal,
                animation: tween,
            });
        }

        return () => {
            scrollTriggerInstance?.kill();
            tween?.kill();
        };
    }, [isMobileMode]);



    return (
        <div ref={ref} className={`decor-blob decor-blob--type-${typeNum} ${classAnimations.join(' ')}`}
            // Hiding SSR inline styles for better security score: Content Security Policy Contains 'unsafe-*' Directive
            {...(typeof document !== "undefined" && {
                style: {
                    width: `${width}px`,
                    filter: `blur(${blur}px)`,
                    opacity: opacity,
                    left: `${x}px`,
                    top: y ?? `${y}px`,
                    zIndex: zIndex,
                    bottom: yBottom ?? `${yBottom}px`,
                    transform: `rotate(${rotate}deg)`,
                    animationDuration: `${animDuration}s`,
                }
            })}
        ></div>
    );
}
